export default {
  birth_order_wishes_and_blessings: {
    first_born:
      'It is my desire that you understand that leadership is more about leading by example than lording it over others. As our first-born child I hope that you will see yourself as the conductor of a music orchestra rather than a star player in a soccer team. May God grant you the grace of leadership through service. May your siblings find shelter under your tree in times of trouble and distress. May you be a perpetual rejoicing - a sign and a lamp that show God’s way in and with our family. May you have a generous heart towards your siblings and in consequence, may your ground be rich, your opportunities plentiful and your barns full. I wish you all the blessings of heavenly places and the fruitful earth.\n',
    middle_children:
      'It is often said that middle children struggle to find their own identity and place in the world, but it shall not be so with you. You are unique in gifts and personality – there is no one quite like you. Your place in the family and in the world is certain. Your uniqueness will lead you to your special place. and You will be least in nothing. Your elders will listen to your voice and your younger ones shall rise to greet you. You will be mighty in word and deed and your dwelling places peace and your gates prosperity.\n',
    last_born:
      'Though you are last in birth order, neither of you nor your siblings shall be least in everything good. You will be a neighbor to your siblings and all your lands shall be vast and beautiful. You will be as a fruitful bough. You will bear fruit in due seasons and your leaves shall not wither. Rainfall of generous and gentle portions shall fall upon your lot and your fields and work shall abound both for you and for all in need. In all things you shall be blessed, and the right hand of the Almighty will keep, prosper, and nourish you forever.\n',
  },
  those_who_own_their_own_business: {
    starting_out:
      'Starting a business is a noble and brave enterprise. As you know we have many stories of outstanding success and failures. I know that at times you will feel as if you going to conquer the world and sometimes you will wonder if you should not have stayed in the safety of paid employment. I pray first that your courage will not fail and that you will fully trust the One who brings dreams to pass. I pray that you will receive insight on where and how to begin and, your journey will be fueled and accelerated by the limitless and boundless riches of divine providence. I pray God grants you robust health and dynamism and that your all your client interactions are pleasurable, valuable, and profitable. Finally, I pray that even in the infancy of your business, you develop the systems and processes that will grow and scale your business in size and profitability. May your harvests be long and your winters short.\n',
    building_out:
      'Dear child(ren) as your business grows into its mid stage years - I congratulate and wish you every blessing of growth and sustenance. I know that you will at some stage pass through valleys in which closeness and intimacy with clients is tested due to your size. I also know that there might be challenges in setting out processes that do not turn away those early customers who valued dealing with you in person. My first prayer is that you will find and retain the best people. That your staff and suppliers will be skillful, fired up and enthusiastic about the success of your business. I pray that the dew of heaven waters your lot daily bringing daily re-creation and cycles of perpetual blessing. May your winters be short and your summers long. May Gods rhythms and cycles attend your planting and harvesting and may your business be well spoken of at home and abroad. May you by God’s grace not become a slow lumbering goliath - but rather a nimble and skillful David - may you outdo and outperform larger and bigger enterprise. Finally, may humility be as your cape and covering and may the foot of pride or complacency come not near you in Jesus’ name.\n',
    handing_over:
      'A large business has many advantages; one of which is the opportunity to define new markets and prepare for yet another cycle of green shoot growth. Dear children, I warn and hope against every form of complacency and pride in your accomplishments and the taking of your customers or market position for granted. I pray that you will receive a new vision for the future that challenges, humbles, and invigorates you. I pray upon your fields, the challenge of expansion and improvement and that these opportunities will be met by the gracious rains of God. I pray that all your people continue to grow and that as the fields bring new shoots of themselves, you develop your staff imbued with your values and vision. May your lot continue to expand and may all your children who desire a place in the business be capable, skillful, and resourceful. May Gods favour rest upon your business for a thousand generations - may you stand when others fall, may you bloom even in places of difficulty and may you be always thankful, always humble in Jesus’ name. \n',
  },
  those_in_education: {
    those_about_to_enter:
      'I cannot speak highly enough of a top-class education. But even more important is the hard work and sacrifice that needed to stand out from the pack. As you enter through the doors of your chosen institution, I pray that success will welcome you and be your constant companion. I pray that Gods lamp will shine upon your head, giving insights and unmatched quality to the work you do. I hope and believe that all your companions will be chosen of the Lord and that you will together spur each other on to ever greater heights. It is my fondest hope, desire, and prayer that this will be the beginning of an endless golden age for you and all those who love and cherish you - that success will not be just a piece of paper, but rather beautiful doors of opportunity will open at your approach.\n',
    those_already_within_school:
      'As you move from one level to the other within the educational sphere, perseverance, grit, and courage are needed so that you are not deterred success or temporary setbacks. I pray for inner strength and fortitude to keep going when others stop. I pray you will see the vision of your future clearly and that success will herald your steps to the heights. It is important that you find or rediscover joy in learning and I pray that your education will not just be one more chore to get out of the way, but that rather, it will be a meaningful journey of discovery that will transform family, community, and the world.\n',
    those_leaving_or_who_have_just_left:
      'You’ve done it. You’ve walked in the steps of those family members that walked this way before you. I am proud of you. Before you lie many choices and now you need wisdom to know which door to open, and which to leave closed. May wisdom and light flood your heart - may you receive insight beyond this present moment. May you be drawn by a prosperous vision and not by mere need. May you receive courage, if necessary, to walk the road less travelled. In all this, may your health be strong and may multiple doors of opportunity and welcome stand open before you. I am so proud of you - for the many years of sacrifice and endeavor. I look forward not only to hearing good news of you, but of seeing your success from wherever I am. And I trust that you will continue to provide sound contributions to the growth and prosperity of our great family.\n',
  },
  those_in_employment: {
    entry_level:
      'Success has a beginning and sometimes that beginning is humbler than we expected. Sometimes it is higher than our biggest dreams. But in all starts, some non-negotiables are necessary to move it forward, divine providence, curiosity and focused hard and intelligent work. So, my wishes and prayers for you are that from the moment you enter your new office; favour will surround you as a sweet mist - the best and the greatest will be drawn to you and your words will be eloquent and pleasing. I pray you will be curious - not in an offensive and disparaging way - not in critical way, but in a way that shows that you respectfully want to learn all you can about your new work so that you might serve it better. I advise that you regard no task as inferior and that you take every opportunity to build relationships, networks, and multiplied abilities everywhere within the enterprise. May the treacherous and vile not be attracted to you and may the enterprising and wise be your companions and may the chief men and women be your mentors and guide. May good fortune and success attend all your undertakings. \n',
    mid_management:
      'In middle management, the beginning is far behind, and the future often cannot yet be seen. Many people in your circumstances get lost; some return to the certainty of what they have left behind - micromanaging others and generally becoming hated pests. Others try to fly too early and end up crashing to the ground. My prayer for you all is for good apprenticeship at this level, excellent craftsmanship in your work and the supervision of those who by dint of their own excellence do not see you as a threat to be subdued or overcome. I pray that your road will have light and that even when fogs of uncertainty swirl around you, an inner compass of goodly words heard from infancy will lead you aright. May you never stumble. May your steps upwards be deliberate, well thought out and may the ground hold fast beneath your feet. There is bound to be competition and maybe sometimes envy and resentment. I do not pray that you will develop a thick skin to all these things, but that rather your perspective and vision will be so wide that you will count all these things as necessary steps to good success. May you have the boldness to stand out for what you believe and the humility to make sacrifices in the interest of the common good.',
    senior_positions:
      "Many have reached the top and found it empty and disappointing, but it shall not be so for you. I pray that your eyes will be clear and your hands and purpose strong and that in this moment you will most clearly see the mandate you have received from heaven and all its attendant care during your journey. May you see how you can create virtuous systems that help the most people and heals the ills of the land and especially your marketplace. May the purity of your early intentions be sustained and may you be in good health and high energy. I pray that you have not forsaken your family on the way up and I further pray, that should there be anything lacking in your attentions towards them, you might find the strength and courage to retrace your steps and attend your children's events, play with them and date your wife as before. May the Lord be at the centre of your life, holding all that concerns you in delightful balance and harmony. I wish you all, the best of Gods daily ministrations - remain His masterpiece.\n",
  },
  those_with_children: {
    very_young_children:
      'It is my prayer that your children will bloom and bring forth the best of fruit. Children are rapidly being seen as accessories to parents’ success – just like a designer bag and so, parents try to pressure them into behaviour that attracts public praise. Instead, I pray that your eyes will be enlightened to know your children as God knows them; that you may view them as precious and unique gifts and not as ornaments. I pray that you will not be impatient with them but that through divinely inspired grace, you spend time and attention to know them. I pray that your work will not take the best of your life, that your days with these young shoots will be fruitful and joyful for all of you. It is my hope and earnest prayer that you will teach them to share, to respect boundaries, to communicate and not manipulate and of course to participate in family life even in the most modest of ways - I pray that you will not look down on their ability to hear and understand the old family stories -of where we came from and why we stand where we stand, and of course our hopes for their future. May God grant you the wisdom to cast these stories as an adventure and not as a burden or weight. Finally, may they grow and flourish, may the soil of their childhood be sweet and rich, may they incline towards God as their sun and may wisdom be their principal fruit in Jesus’ name.\n',
    young_adults:
      'As your children grow, they will make more unsupervised contact with the world. May the bounds of love constrain them from excess, may the hands of God as the first gardener lovingly prune and shape them. Their trunks will be broad and strong as the Sequoia tree and their roots, will find the best and the purest of waters, their crowns will be expansive like ancient trees and their wood will be elegantly appointed as the cedars of Lebanon. They will bring forth a fragrant smell that will repel wickedness and attract all that is high and beautiful and godly. If they should pass through the turbulence of development, may the everlasting arms uphold them and lift them up when they fall. May they recognize the beauty, nobility and wisdom of God and may they choose him for themselves. Because of this, may they be well spoken of, find favour in near and distant lands. May the gates of cities open to them and may their energies not be wasted. May they grow in skill in their respect crafts - standing strong upon the eternal foundation that can never be moved. May they be brought into the councils of the family and may they as young princes and princess, take decisions with fairness, the fear of God, wisdom, and love. May all that is yours be safe in their care and may they cause you to smile and laugh. May your joy in them be full and together may you lack nothing that is profitable for your family.\n',
    grown_children_with_their_own_children:
      'Now the oaks are grown and underneath their regal crowns are smaller trees in various stages of growth. May their fields be beautiful and may they be nourished from above by the dew of heaven and the gentle showers of divine providence and from below by the richness of the earth. Their roots are strong, they search for, and find water, they establish the family name, and they bring protection to the young saplings. May they all dwell together in unity so that they receive the commanded blessing from above - even life forever more. I pray that in their advancing age sorrow and regret will be far from them - that they will age well in joy. I pray that heavens mandate will be safe in their hands and communicated to their children carefully and precisely. May their years be long and may all that they do prosper. may they stand on greater mountains than we did in our day. May their children honor and reverence them - may their words be taken in as water in an arid land - may age honour them and health be their belt. May their faith not fail and may they safely steward the transitions of the family story, values to the next generation. may they see the scrolls taken up, may they see them lived and may they see them acted upon. May fair and gentle winds blow upon you all the fruits of our strength and may yours never fail. May joy and good success attend all your efforts and may your legacy be first divine, full, and finally believed and extended by the future generations.',
    married_without_children:
      'My dear child, children are a gift from the Lord, and they will come as they come. Delay is not a sign of disfavor but maybe a sign that there are other things at hand that are of even greater importance to the Lord. Comfort your spouse with these words. You shall not be desolate; the high name of the Lord will answer for you and the fruits of autumn shall be sweeter than the fruits of summer. May your hearts all rest in peace and anticipation - may you not be troubled because you are clothed by Gods glory itself and his love is your ornament. Yet, favour will answer you and the almighty God will hear you as he has heard your ancestors in their distress. He will comfort you with one hand and appoint and furnish you with the other. Desolation shall be far from you. Your fields will be sown, and they will bud, and the sound of little running feet shall be heart in your estate. You shall not be small - for the mercies of the Lord will extend your tents. Faithful is he who has promised and who will also accomplish it. For now, you walk under the undiluted favour and attentions of the Lord, He is at your right hand you shall not be moved. Children will run to greet you; they will tell you their stories and you will tell them ours. God bless you dear child.\n',
  },
  marriage: {
    for_the_first_time:
      "My children as you well know, marriage is an honorable institution designed by God for character development, mutual enjoyment comfort and impact. In our family, we have always placed marriage as something of critical importance to our future success. I pray that God helps you be generous yet ambitious, loving yet truthful with your spouse. May you both be blessed beyond measure; may you be fruitful in body and business. May you be a lighthouse that helps others find their way. May you be a sweet haven and home of peace. May the lamp of heaven shine upon your head and may all your steps be guided by God’s light. May your fields all be sown and your barns full. May you live to old age and see and train up your children's children!\n",
    for_the_second_time:
      "The righteous may fall seven times but he/she keeps getting up. I am delighted that you have not given up on the joys and the lessons of marriage. We can learn from our mistakes and mishaps. We can be stronger, better, and more capable the second time around. We can refuse to let misfortune define us. We can agree with God’s kindness and providence for our lives. With all this in mind, I bless you with the blessings of Sarah and the blessings of Adam and Sarah. Though happiness was delayed - it will not be denied. I pray that that this marriage will not be a shadow of anything that went before it. I pray in the name of Jesus that the sweetness of this marriage will make you as Joseph whose latter success caused him to forget all the earlier troubles of his father’s house. You will walk under clear skies, with strength, purpose, and a newly birthed vision. Your gates shall be joy and praise and your end shall be good. You will by grace see your children's children and they shall learn from you what grace and God’s providence can do. By God’s grace you will be a perpetual example of His restorative grace and blessing.\n",
    delayed_marriage:
      'My child, hope deferred can make the heart sick - but I love how that sentence ends "…but a longing fulfilled is a tree of life" I pray that your expectations for a happy home will not be cut off. I pray that God will keep you in a state of bloom - that every day you will be refreshed by the dew of heaven and that your roots will draw in the sustenance of life. I pray that when that day comes as it will, you will be healthy, prosperous, and full of life. Until then I pray that your vision is renewed daily and that you find growth, thankfulness, and happiness every day. May anticipation and delight fill your heart and may your dreams be surpassed by the ownership of God himself as the experienced higher and better treasure.\n',
  },
}
