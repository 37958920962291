<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="87.5" class="progress--header"></ProgressBar>

      <h1 class="page__header">Closing</h1>
      <p class="page__description">
        You are almost done. Congratulations in advance!
      </p>

      <p class="page__description">
        Now all you must do is to sign off your letter. You can choose a sign
        off statement from the choices below:
      </p>

      <div class="step">
        <p class="step__text">Choose a closing greeting</p>
        <div class="step__input">
          <div>
            <div
              class="field"
              v-for="(greeting, index) in closingGreetings"
              :key="index"
            >
              <div
                class="
                  field__wrap
                  field__wrap-checkbox
                  field__wrap-checkbox--multiline
                "
              >
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    :id="`checkbox${index}`"
                    name="greeting"
                    placeholder="Name"
                    :value="greeting"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label :for="`checkbox${index}`"><span></span></label>
                </div>
                <label :for="`checkbox${index}`">{{ greeting }}</label>
              </div>
            </div>
            <div class="field">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    id="checkbox5"
                    name="greeting"
                    placeholder="Name"
                    value="customGreeting"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label for="checkbox5"><span></span></label>
                </div>
                <label for="checkbox5">Create your own sign off</label>
              </div>
            </div>
            <div class="field" v-show="customHeader.show">
              <div class="field__wrap">
                <textarea
                  class="field__textarea field__textarea-check-triggerred"
                  id=""
                  cols="30"
                  rows="10"
                  v-model="closingGreeting"
                  placeholder="Custom Sign Off"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link
            :to="{ name: 'Blessings' }"
            class="button button--secondary"
            >Go Back</router-link
          >
          <button
            @click="submit"
            class="button button--success"
            :disabled="!validateInput || loading"
          >
            <span v-if="!loading"> Finish </span>
            <span v-else>Saving...</span>
          </button>
        </div>

        <div style="color: #ff0000; margin-top: 3rem">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/closing.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import http from '@/config/axios'
import ProgressBar from '@/components/ui/progress-bar'
import { capitalizeFirstLetter, deSlug } from '@/utils/convert'
import blessingsMap from '@/utils/blessings-map'
import regretsMap from '@/utils/regrets-map'
import imagesMap from '@/utils/image-map'

function getValueFromNestedObject(value, objMap) {
  const cleanPayloadObj = {}

  Object.entries(value).forEach(([keyObj, valueObj]) => {
    if (valueObj !== '') {
      cleanPayloadObj[keyObj] = valueObj
      cleanPayloadObj[keyObj + '_pair'] = objMap[keyObj][valueObj]
    }
  })

  return cleanPayloadObj
}

export default {
  name: 'Greeting',
  components: { ProgressBar },
  data() {
    return {
      options: '',
      closingGreeting: '',
      loading: false,
      errorMessage: '',
      customHeader: {
        show: false,
      },
      closingGreetings: [
        'It has been my highest honour to be your parent and to watch you grow into the person that you’ve become.' +
          'I have written this letter with the utmost sincerity, love, and hope for a blessed future for you and your family.' +
          'Love',
        'You have been a true delight and parenting you has been an honour and privilege.' +
          'There were difficult moments to be sure but in my hearts of hearts I knew that they would not last. ' +
          'I send you this letter to light your path from the perspectives of my life and times.' +
          'It is not a road that you must walk exactly as I have done but let it be a guide on those days when you cannot hear my voice – let it speak to you with all the love with which I write it today.' +
          'All my love',
        'Through the times of light, joy, and happiness as well as through times of clouds, heaviness, and sorrow. ' +
          'My love for you has been constant. This letter is my unending voice bearing my hopes, dreams, and advice to you and your generations. ' +
          'The future is always unknown, but I trust that God who has led us all thus far, will lead you safely to the place of contentment and peace.\n' +
          'With my dearest wishes for your unending success',
        'I have watched over you from babyhood to adulthood - as you slept, ate, and played. Through health and the little illnesses of infancy. ' +
          'In times of joy and times of sadness. There has been one constant – my complete joy and satisfaction in you. ' +
          'I have written this letter in the hope that in the future when I am no longer as available as before, you may be comforted, encouraged, and directed to live the highest and best life. ' +
          'I am confident of your future. I have always been. Should you slip or fall, or should the sun give way to rain never forget who you are, what you represent and to whom you belong.\n' +
          'With eternal love and gratitude',
      ],
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('closing')
    if (storedData) {
      this.options = storedData
    }
    if (!this.closingGreetings.includes(this.options) && this.options !== '') {
      this.options = 'customGreeting'
      this.closingGreeting = storedData
    }
  },
  methods: {
    routeToNextPage() {
      this.$router.push({ name: 'Success' })
    },
    submit() {
      this.loading = true

      storage.storeDataToLocalStorage('closing', this.closingGreeting)
      const storageData = storage.getAllLocalStorageData()

      const payload = {
        ...storageData,
        added_by: storageData.account.name,
        email: storageData.account.email,
      }

      const cleanPayload = {}

      Object.entries(payload).forEach(([key, value]) => {
        if (key === 'blessings') {
          cleanPayload[key] = getValueFromNestedObject(value, blessingsMap)
        } else if (key === 'regrets') {
          cleanPayload[key] = getValueFromNestedObject(value, regretsMap)
        } else if (key === 'images') {
          const cleanPayloadObj = {}

          Object.entries(value).forEach(([keyObj, valueObj]) => {
            if (valueObj !== '') {
              cleanPayloadObj[keyObj] = imagesMap[valueObj].key
              cleanPayloadObj[keyObj + '_pair'] = imagesMap[valueObj].value
            }
          })

          cleanPayload[key] = cleanPayloadObj
        } else if (key === 'lessons') {
          const cleanPayloadObj = {}

          Object.entries(value).forEach(([keyObj, valueObj]) => {
            if (valueObj) {
              cleanPayloadObj[keyObj] = capitalizeFirstLetter(deSlug(keyObj))
            }
          })

          cleanPayload[key] = cleanPayloadObj
        } else if (typeof value === 'string') {
          cleanPayload[key] = capitalizeFirstLetter(value)
        }
      })

      payload.email = payload.email.toLowerCase()

      this.errorMessage = null

      return new Promise((resolve, reject) => {
        http
          .post('/letters', cleanPayload)
          .then(response => {
            this.loading = false
            resolve(response)
            storage.clearLocalStorage()
            this.routeToNextPage()
          })
          .catch(error => {
            this.loading = false

            this.errorMessage = error.message
            reject(error)
          })
      })
    },
  },
  computed: {
    validateInput() {
      return this.closingGreeting !== ''
    },
  },
  watch: {
    options: function (value) {
      this.closingGreeting = ''
      this.customHeader.show = !this.closingGreetings.includes(value)
      if (!this.customHeader.show) this.closingGreeting = value
    },
  },
}
</script>
