export default {
  1: {
    key: 'One step greater than enjoying serenity is to immerse oneself in it body, soul and spirit. We were made from the clay, so we have affinity with the elements of earth. Learn a skill that enables you interact with the earth; painting, pottery, fishing, or a hobby such as bird watching, butterfly photography. Don’t be passive in solitude - engage with it and bring out something that did not exist before.',
    value:
      '1. We interact with people, and it fills our minds with memories and experiences - some good, some bad. Don’t live out your entire life without engaging with the silent sentinels and watchers of the earth - the mountains, streams, trees, and flowers. It may sound odd, but they have so much to teach us. They were here before us, and they will be here when we are gone - isn’t it a good idea to learn from them?\n3. At the end of the day, success is not just about winning, it is about being part of something greater than yourself. It is like jumping into a stream made up of beautiful ancient stories and allowing it to carry you along. It is fusion with God and surrender to a greater will and purpose for your life.',
  },
  2: {
    key: 'In every family there are leaders, and our family is no different. However, I would like our concept of leadership to differ from the commonly accepted sole leader who towers above everyone. I would suggest that in addition to a central coordinating leader, we embrace the idea of having leaders for every phase of family growth. Let’s see ourselves as an orchestra, in an orchestra, there are many instruments, and they take turns to lead but they sound sweeter when all the other musical pieces are playing and are not silenced.',
    value:
      '1. It is necessary for our family members to have a domain of skill and even better if they find and acquire some proficiency in our family domain of skill even if they are placed under no compulsion to work within it. These domains of skill are a living conversation and fellowship - not only with those who are living but also with those who have passed on.\n2. I would like our family leaders to see themselves as music conductors. the music conductor brings the tone and beauty out of every instrument while at the same time maintaining the rhythm and harmony of the music. Don’t be a dictator, identify the roles that our people are competent and joyful in, and allow them to express their gifts in a way that is meaningful to them as well as to the family.\n3. Our family should have holidays and season that bring us together. Practicing togetherness often helps us fully understand how to work together. Treat these moments as a sacrament that is as important as going to church; plan them for enjoyment as much as for business outcomes.\n4. It is important the orchestra reads from the same music sheet. In the same way, it is important that all family members understand that they were born into a moving stream of family history, values and beliefs that flow within an even more powerful ocean of Gods story and values. Our family story is not greater than God’s story, rather, it must fully reflect it. Understanding the intertwining of personal, family and Gods stories is vital if our family "orchestra" is to play beautiful music.',
  },
  3: {
    key: 'Partings are an inevitable part of life. We understandably hate them and do everything to avoid them. Nevertheless, partings free individual members to become all that they are meant to be. Partings also herald a future reunion with people who have experienced growth while alone or in a difference time and space. All partings are temporary - even the parting of death which appears to be final. We must always live in perpetual hope of an eventual reunion. Partings create space for family members to grow and they represent a vital part of making our family stronger. In as much as we hate the parting, let us keep in mind the tremendous gain that will be ours when inevitably we are reunited. Let us instead treasure these moments and use them to become wiser and stronger. the moments of absence will give us clarity, space, and opportunity. If these opportunities are seized our reunion will be all the sweeter.',
    value:
      '1. The pain of parting is also the expansion and stretching of personality. Without this stretching we will remain limited and constrained - therefore we must learn to part well that we may be reunited well.\n2. How do we "part well?" We part well by regarding our moments together as precious, valuable, and holding inestimable value. the memories we make today will strengthen us for the seasons we are apart.\n3. Where it is possible the moment of parting must contain the assurances that in one way or another we will be reunited and for us to joyfully bear these temporary sorrows with joy and courage. Where possible a hug or embrace will be long remembered - a gift or special moment will be an anchor for the soul. The more unusual this moment or gift, the more the mind will make a refuge out of it.',
  },
  4: {
    key: 'It has been my experience, that there are great benefits to withdrawing from busyness and entering a time of solitude and reflection. Solitude cleanses our soul from the daily debris of emotional highs and lows, hurts and the pressures of living - especially for those living in cities or those with fast paced lives. But we all have a fear of stopping our endless activity. Somehow, we have come to believe that everything depends on us and that stepping aside and away into reflection somehow means letting others down. The benefits of contemplation and reflection are well known - the dragon we need to slay is our addiction to the urgent and the fear that everything will unravel in our absence.',
    value:
      '1. Solitude and reflection are the foundations of the best work you will do. Don’t work to rest – you will never get there. Instead, rest to work.n2. There is an energy within creation that allows us access to ideas that are elevated and innovative. Allowing the rushing debris of information that flows through our minds an opportunity to sink to the bottom while clarity rises is the key to successful creation and enterprise.\n3. We were made to create but our busy frantic world inspires confusion and self-doubt - taking time out to meditate against the backdrop of creation is the beginning of the creative process.\n4. I suggest that when picking a retreat venue for the family, it is a good idea to look for a place that is beautiful, serene, and quiet - so that you can take the best decisions possible for both current and unborn family members. I suggest also that individual families take out time several times a year to connect, pray and plan.',
  },
  5: {
    key: 'In every family - including ours, there is a tendency for people of like interests to band together. Society supports this type of atomization, but we must not. I want to encourage you to support people different outlooks and character. This is especially powerful when it happens between people with different backgrounds and personal characteristics. There is a growth of our capacity to understand and care for others that needs to happen for us to remain "interiorly" wealthy.',
    value:
      '1. Practice diversity within highly supportive environments. It will bring out of you deep riches and new previously unknown capabilities and skills that you previously thought were impossible. Think across age groups, tribe, race, language and level of wealth and experience\n2. A family that embraces diversity is not asking each member to become less to accommodate others. Diversity encourages us to fully be ourselves with each other and in consequence create something new that was not there before. In the same way that the black and white keys on a piano retain their integrity and yet bring out the best in each other - so must our family members enable and support difference.\n3. Encourage the next generation to take a time off in their lives to live and work in a completely different environment. Whether it is shearing sheep in Australia, planting with farmers in Malaysia or waiting at tables in London, our future prosperity requires differentiated experiences to remain robust and strong.',
  },
  6: {
    key: '',
    value: '',
  },
  7: {
    key: 'There is nothing quite like a wedding to set family pulses racing. Weddings are important parts of our family life. They mark the beginning of something new that we can see but not yet fully grasp. For some families, marriage marks the splintering of the family tree - but this is not our hope or expectation for our family marriages. In our family, marriage is both additions and expansion. It is never a subtraction. How the family has conducted themselves in the past; whether we see ourselves as a "nest" or as a "team" will determine whether it is a splintering of the tree or a thickening of the tree.',
    value:
      '1. "Nest" versus "Team" A nest marriage is very different from a team marriage. In a nest marriage the family is losing someone. A member of the family is going to start their own independent enterprise. The family\'s role is done and from now on, a new independent family will be doing its own thing and in turn at some stage it too will break up to start again. In a family team, this is quite different. In a family team, a wedding is a celebration not a wake. It is a celebration of expansion - family members never leave - they are always part of a whole.\n2. Let the new marriage remain at the centre of the occasion. Sometimes the central focus of the wedding can shift to the guests or important personalities in attendance. Sometimes more attention goes to simply, impressing the guests. But you should do all you can to make sure that the couple are at the centre. Having said that of course I strongly suggest that you make the family the backdrop\n3. Welcome the new family member warmly. Speeches are usually dry and most guests; family and outsider simply want the event to move on to its conclusion. Try to design an event that is warm, short, filled with little bits of tradition and of course a hearty and warm welcome.',
  },
  8: {
    key: '',
    value: '',
  },
  9: {
    key: 'It’s so important to know how to move forward in difficult moments. There are times in life when it seems easier to go backwards into what is familiar than to go forward into uncertainty. As a family, we have faced such moments before, and we will face them again. Knowing how to move forward in times of uncertainty is a huge skill. Being paralyzed by the expectations of parents, peers or even the history of family achievements itself is much more common than you think. I have a few ideas from the world of world class gymnasts that may prove useful.',
    value:
      '1. First, focus on what you want to achieve and then break the power of inertia by doing something - the gymnast, rocks on her heels, or takes a small hop before beginning their run. It is important that you break through inertia by taking a small step. Once you’ve done so keep moving. Your first step doesn’t need to be huge but what it does is that it unlocks muscle memory, and you begin to perform subconsciously free of fear.\n2. Accelerate. You now need to gain momentum. You don’t have to do a lot of big things, but you do need to keep moving and keep doing. Habit is powerful and I have found that once momentum has been established it has a life of its own. This is how many of the opportunities that came our family’s way were created and captured.\n3. Always before the gymnast makes the big move, he/she must remain balanced. For you that balance means fulfill all your other roles - father, mother, son, daughter and so on. It’s remarkable how executing these commonplace roles can centre you and give you the balance to launch your big move. And once the big move is executed - be sure to land on your feet. Come back to earth. Success and mixing with the high and mighty is a fantastic feeling but always remember to come down to earth. It will help you execute the next big move even better.\n/she must remain balanced. For you that balance means fulful all your other roles - father, mother, son, daughter and so on. OIts remarkable how doing any of these roles fully can centre you and give you the balance to launch your big move. And once the big move is executed - be sure to land on your feet. Come back to earth. Success and mixing with the high and mighty is a fantastic feeling but always remember to come down to earth. It will help you execute the next big move even better.',
  },
  10: {
    key: 'Fostering and nurturing the younger members of the family will mean spending time alone with them. This is an intentional moment which if crafted with grace, allows the child to see themselves as a unique and valued member of the family. I especially recommend taking walks together. It was a feature of my younger days. Walking provides so many interesting points of conversation. It provides solitude - but not the solitude of being in a room together but of having ancient trees and nature as companions and onlookers. ',
    value:
      "1. Being together with our young people can be accidental but it works even better when it is deliberate and well planned. Taking time out to be with a younger family member is not unlike a moment of election and coronation. It is a valuable moment in which the family's true wealth can be passed on and assimilated.\n2. Limit yourself to passing along one big grand idea rather than cramming too much into the conversation. Give space for your words to be assimilated like the fresh morning dew that daily sustains the wilderness.\n3. Enter slowness when you are together. No matter how short the time, be unhurried and deliberate; listen as much as you speak and let him/her know how much this time means to you.\n4. There is a power in touch - especially when it is made with loving consideration. A touch, hug or embrace is like carving words into stone tablets. these actions of reaching out across the generations write more deeply in the subconscious memory than a long lecture. Invite these senses of touch, smell, sight, and taste into these moments to create a powerful memory that will can be passed from one generation to another.\n/her know howmuch this time has meant to you.\n4. There is a power in touch - especially when it is made with loving consideration. A touch, hug or embrace is like carving words into stone tablets. these actions of reaching out across the generations write more deeply in the subconscious memory than a long lecture. Invite these senses of touch, smell, sight and taste into these moments to create a powerful memory that will can be passed from one generation to another.",
  },
  11: {
    key: 'I suggest that family members treat their occupations as an ancient craft containing mysterious secrets and insights for those who have completely submitted to its demands. This means many hours of deliberate practice and experimentation. Like the potter we want to make things that are not only valuable, but which also stand the test of time. We do not want to create products and services that end up in the rubbish heap after a few years. rather we want to build beauty that is handed down from generation to generation.',
    value:
      '1. To become experts in our chosen occupation, we must spend time on it. We must deliberately practice and experiment - we must spend many hours in private, endure many secret failures before we become accomplished. Do not be reluctant to deliberately practice hard in secret. For every hour of public performance spend multiple hours in hidden and private work. How we spend our time is hugely important. Too much time in public without even more time in secret will produce declining results.\n2. Work with intense concentration until you enter a state where you and your work become one. In my time this moment of connection between creator and the created was known as "flow". Flow is the result of deep concentration without distractions. Clear your mind, clear your space and work on only one thing at a time - treasure the moment of connection with your work. It might not last a long time, but it will produce outstanding and beautiful products and services that people will travel miles to see and enjoy.\n3. It follows that you must not be afraid of solitude. Find a place where you can enjoy solitude and aloneness. It must also be a space that inspires you. Whenever you rent, buy, or build a house, provide for your "holy space" first before the public spaces.',
  },
  12: {
    key: '',
    value: '',
  },
  13: {
    key: 'Now I want to talk a little about disability or limitations. All families have a measure of disability even when they act like all is well. We have had our fair share of them. Disabilities may include some festering feuds, difficulty in reaching agreements, weakness in decision making or a shameful family secret that erodes confidence. It might be structural - for instance difficulties between stepchildren or cousins. Sometimes it will be a single or a group of overbearing family members who deprive other members of the space and incentives to grow. As a family, we have had our own disabilities - areas of weakness that improperly addressed could invite catastrophe. We have encountered such challenges in the past and I want to share with you how we overcame them.',
    value:
      '1. Accept the disability as a family uniqueness. A lot of energy and focus can be lost by trying to remove the problem. In the short term, it is probably better to see this disability as part of family history and to see how convert it into a strength.  Come to terms that the family will never be perfect and that in fact no family is perfect. Own the limitation, don’t hide it. It takes tremendous effort to hide family skeletons. Efforts that could be better applied elsewhere. It may well be that your family is actually best equipped to help other families through similar circumstances.\n2. Compensation. Just as a disabled athlete develops strength in other areas of their body - we can work on strengthening the healthy areas of our family life. You know, sometimes healthy habits can spill over to solve unhealthy habits elsewhere. This must be one of our objectives - to cancel darkness with light and despair with joy.\n3. Play the game to your strengths. Every family has a preferred way that they would like to live and prosper. Many of these ideas are fantasies that do not reflect reality. Construct family governance, communications and decision making in such a way that disabilities are offset by your strengths. Be honest with your limitations and accept that our family will have to play the game a little differently to other families.\n4. Make no excuses. It is easy to receive the sympathy of others, but this will not help us. We must continue to expect the best from our people even in difficult circumstances. We must circle the wagons and fight for what we believe. The largest fires start from a small spark. Don’t try and fix the whole family, make what you do work and then interface with others doing same. ',
  },
  14: {
    key: '',
    value: '',
  },
  15: {
    key: '',
    value: '',
  },
  16: {
    key: 'I have learned a lot from the world around me. One major lesson is that the thick forests we see today, were small insignificant looking seeds many years ago. Meaning that sometimes it may appear that your efforts are small and insignificant, but we should never doubt the power of time and providence to create something immense from our small efforts. How can you bring this into your daily walk?',
    value:
      '1. Do not favour the spectacular over good daily and weekly habits " Do not despise the day of small beginnings."\n2. The most important thing in your activities is the quality of the seeds you plant. Are they done in love, with all your heart and mind? Are they “just good enough” or are they excellent? Are they things that other people can water or are they mysteries known only to you?\n3. Did you have another generation in mind when you planted them? And finally,\n4. Are they free of hate, selfishness, and pride?\nIf your work is done in this manner, it will possess the greatness and longevity you desire, and many future generations will call you "great" and "blessed."        ',
  },
  17: {
    key: 'Encourage grandparents to show their love. Some grandparents are reserved but all are loving. There is nothing that stands in a child’s memory as being loved extravagantly in public. There will come a time in the teens when they temporarily dislike such open shows of affection especially in front of their peers. But before the teen years, they absolutely relish it. Sitting on a knee, being held, or carried - any display of love that involves elongated periods of touch warm the heart and open it up to knowledge and love.',
    value:
      '1. Health and safety are important. Grandparents cannot always do the things that they used to be. Make sure that they do not exert themselves. They are unlikely to show their discomfort and you must be sensitive to when it is causing them physical strain. Grandparents are also likely to count the pain as "worth it,” so it is best for to join them in the carrying, share the "joyful burden" or move them on to another activity that is less strenuous.\n2. What happens in this moment of physical proximity is important - words spoken will hardly ever be forgotten so that is the time for both grandparent and parent to affirm the child or impart some wisdom.\n3. What happens if a grandparent has become bitter and unpleasant or does not favour their own children? It is unlikely that any animosity will spill over to the grandchildren, but the situation should be watched with sensitivity. If there is bitterness and unpleasantness towards any of the parents visits to the grandparents will have to be heavily curated to avoid the spill over of bitterness to another generation. Parents must understand what special skills grandparents have and allow these to be the point of focus. Parents must also be ready to explain any grouchiness from grandparents in a way that is generous and loving.',
  },
  18: {
    key: 'Life has taught me that wealth is a cycle and that unless it is shared with the poor and defenseless, it will dry up at the same rate that our hearts become hard and unyielding. Our family has received a heavenly mandate to care for the less fortunate by creating self-sustaining systems. Do not dismiss or ignore the call. Do not tokenize it - for in your compassion lies our future good.',
    value:
      '1. Most of your friends will be rich, wealthy, and highly educated but you will only reach your full humanity when you befriend and help those who are different to you.\n2. Every family can trace some hardship, deprivation, and challenge in its history. Do not let your current affluence rob you of the energy and drive that made it possible. Do not be ashamed of our history or the battle scars of previous generations.\n3. Every generation is a debtor to the less fortunate who till the earth, clear the drains, and even use some of the products we make. Ensure that you teach your children to pay this debt by making people’s lives better.\n4. We do not just want to throw money at problems - we want to bring our skills and education into the process. leverage your networks and abilities to come up with sustainable and high impact plans for the less privileged for as you do this you will grow in intellect and values and so our “True wealth" will be expanded.',
  },
  19: {
    key: 'It is the responsibility of the older and more experienced members of the family to put an arm around the younger generation when they are discouraged and disconsolate as well as to encourage them to take that extra step when they feel they can do no more. Family leaders must embrace these three responsibilities; to seek for the highest performance from the family team, to encourage collaboration between members by providing a safe, minimal judgement environment. Finally, to benchmark individual benchmarks and encourage the family team member to compete against the benchmark and not against one another. Creating overall positive climate of encouragement and high performance is your responsibility.',
    value:
      '1. Don’t make a God out of success if it takes a bit longer for someone in the family to get it or to make progress - make yourself available. Be a supporting presence not a critical one.\n2. It is sometimes more important to support those who fail than to applaud those who succeed because as you well know, our family chain is only as strong as its weakest link. Take care of the weak links so that the strong links will not be themselves weakened over time.\n3. The world is full of teachers, experts and advisors but let our family also have "Fathers and "Mothers" who can direct, exhort and nurture. Knowledge is not the only requirement of success.',
  },
  20: {
    key: 'I have found that in large families, the weak and the young can be sidelined and conversations with them postponed indefinitely. Yet there is so much good in having time for the young and for associating with the weak. When siblings do this, it is extremely powerful, and I have some recommendations that you may find useful.',
    value:
      '1. Make individual time for your young people. Speaking to them within a large group can depersonalize them and make them feel unimportant or judged. Often you just come away sounding superior or arrogant. I suggest that you take them aside, go somewhere peaceful with just the two of you. Deliberate encounters like this have shaped my life far more than any large family meeting.\n2. Taking time with younger family members is great but you need to do it properly. Have you considered going to somewhere you both have never been before? Somewhere your younger family members can teach you - somewhere where you are both seeing the place for the first time? This levelling of experience is vital to creating confidence and stimulating a two-way communication. Don’t oppress them with places you know - travel hand in hand and explore the world/places together.',
  },
  21: {
    key: 'What can we impart to a child too young to understand or be understood? Do we wait until he/she comes of age? Certainly not. It is not the old and mature flame that requires support, it is the small and weak one. The youngest members of our family teach us an invaluable lesson - that we must nourish the weak and fragile. How we fan these flames into being is by showing them grace. They are still too young to earn our respect and approval so everything we give must be grace - completely unmerited grace in generous portions.',
    value:
      '1. The grace of your presence. Being available to the young members of our family can be inconvenient but it must be done. It is so easy for work and other activities to reduce them to financial beneficiaries. Money can enable a good many things, but it can never replace the warmth and love of a human being. Availability, warmth, and love are critical to fanning the hesitant sparks of childhood into vibrant adult flames.\n2. Touch. Touches and hugs are wonderful communicators of acceptance and love but creating experiences where the hearts of our next generation are touched is even more important. In the teen years it easy for peer pressures to weave a cocoon around the heart and therefore before the onset of this "winter" experiences that touch the depth of the heart must be skillfully orchestrated. Whether it is an adventure holiday, a burger and shake or simply a game of football; the heart of the younger members of our family must be touched and kept opened.\n3. Embrace. I am not talking of physical embracing here (I spoke about that earlier) Rather, I am talking about faithfully holding the hopes and dreams of your children in your thoughts and actions. Don’t make promises lightly, but when you do make them, don’t break them. Unlike your professional life, a broken promise will not attract immediate censure. It will however create a lifelong distrust of authority figures and create an unwillingness to subordinate personal goals for family goals. Whatever you do, don’t break these young hearts. The price for such offences is one we simply cannot afford.',
  },
  22: {
    key: 'In my opinion, family is an unbroken cycle of memories, gifts, talents, and resources. Like a strand of valuable pearls. Do Your own part to keep the cycle of family history prosperous and unbroken. Here are a few things that might help you:',
    value:
      '1. Always remember that the family is larger and more enduring than any one individual member no matter how prosperous or influential that individual may appear.\n2. Your role set against entire family timeline of history is small, but vitally important.\n3. Family heirlooms and memorabilia are important - guard them carefully - they are worth more than their dollar value because they speak to future generations. Try to write a description of what they meant to owners within the time they lived in. This will provide a rich context of meaning to children and grandchildren.',
  },
  23: {
    key: 'As a family, we want to maintain our values, belief, and identity but at the same time we need to create the future on our own terms. This means that we must grow into an entrepreneurial family that somehow unleashes the creative power of its people. There is often a dynamic tension between the older generation that wishes to keep what is best about us and the younger generation who want to break new ground. Such tensions are inevitable, but they do not need to be destructive. Each motive is valid. Both must learn how to work together - not simply through compromise which can result in an average family that is neither this nor that. To remain “wealthy” we must embrace the fact the future will be quite different from the present - yet at the same time there are some things that must never change such as integrity, values, collaboration, sibling love and so on.',
    value:
      '1. Allow your children and next generation to question you, and on your part, reflect deeply on such questions before providing answers - Otherwise we must go away and reflect but we must not forget to answer the questions posed. Curiosity must be rewarded so that future generations do not become bored, disillusioned, and demotivated.\n2. A portion of your wealth should be set aside to reward creativity, inventiveness, and enterprise. This may come through zero interest- or interest-bearing loans to our next generation in return for equity or a bond type return. Your aim is not to make money from your children. Instead, the objective is to stimulate challenge while ensuring our wealth is preserved in real terms.\n3. High quality education is important - especially the one that takes place in places where curiosity is welcome. In addition to education, we must strive to ensure that we intern the next generation in places where they can grow, test, question and expand. They must fully learn rules before they can make products and services that break those rules. This is the only way they will be able create infinitely better things.',
  },
  24: {
    key: 'I have lived in countries with different levels of social inequality and justice. I have been tempted to look the other way. To say "This is not my business" but at such times, my conscience has pricked me. It is my belief that we must work to make the world and especially our societies more just and equitable. We must have answered the question "who is my neighbor". If we do not do this, our society will be left in the hands of wicked and unjust men and the war on the dispossessed will eventually come to our doorstep.',
    value:
      '1. Beyond social conscience and protest, we must use our education and resources to create equitable systems. It is systems that will fight systems. We do not embark on fighting systems or government. God forbid. The systems we build must be fair and just and very easy to onboard. They must be an alternative civilization that attracts both oppressor and victim.\n2. Do not look the other way when you see systems of oppression or injustice. But at the same time ask yourself whether placards and street protests will change anything. Ensure that within our family’s wealth process - we are free of injustice ourselves. Only then can we stand against the external evil.',
  },
  25: {
    key: '',
    value: '',
  },
  26: {
    key: 'Life is not easy, but it can be enjoyable. It is a strange thing, but to make progress, you must conquer not just problems but yourself. To be distinctive, you need to experience life in a way that brings out what is special and unique about you. You don’t become exceptional by buying stuff. You become unique by making hard choices and fixing your eyes on the goal - so strongly, that you can endure the inconveniences and challenges of the moment. You need a high tolerance for frustration.',
    value:
      '1. To build a life that contrasts and connects with beautifully with what is around you, you must walk a unique journey of conviction. Such journeys can be lonely, but they are ultimately rewarding.\n2. Enduring the present difficulty requires that you focus on a goal that many people cannot see or even desire. One of such goals is to be a light house - something that allows people get their bearings, which gives hope that success is possible, and warns of danger.\n3. Don’t be afraid that all this will take a long time. Difficult and challenging things can go slowly at first - but this path is the only one that produces strength, resilience, and true progress. What you build in this way will last for many generations.\n4. Finally, build with ancient stones of family and world history. the present moment may seem to be all that has ever been, but in truth it is a mere dot on an immense canvas. Finding, carrying, and setting these ancient stones down is hard work at first and it may seem that your progress is slower than that of your contemporaries. But I can assure you that if you do this, the rewards and results will be enjoyed by you and your heirs for hundreds if not thousands of years.',
  },
  27: {
    key: 'It is good to have ambition - to aim to be the very best. This is how the great civilizations were made and defended. Men overcame the challenges of a hostile, foreign terrain, and the enmity of others to build something magnificent and enduring. What you built must not merely provide for your existence, but it must soar and elevate into something much greater than yourself. ',
    value:
      '1. Consider the great ceiling of a cathedral or a skyscraper. Somehow the way they soar as if trying to touch the heavens speaks of the immensity of man’s ambition. Always remember that the feelings that drive you on, are an invitation from within you to imagine and do something that speaks of a high and lofty purpose. No matter how mundane your work – there is a type of it that is lofty and grand.\n2. The great buildings all have floors that speak of steps of progress and often the executive floors are higher up in the building. In the same way - as you live, work and build, think of showing others the steps upwards. Don’t hide the steps of progress from your children lest you kill their curiosity and ambition and reduce the immensity of what you have done.\n3. Let your work be a sentinel - let it faithfully watch over something that is of concern to others. Even if it is a service, give it some tangibility so that people can not only experience it, but they can also touch and feel it. Let your work care and protect something noble and special. Don’t make it just about money - make it about purpose - watch over your city and community and you will live long in the hearts and minds of everyone. ',
  },
  28: {
    key: '',
    value: '',
  },
  29: {
    key: '',
    value: '',
  },
  30: {
    key: 'Nothing is as humbling as looking into a star filled sky. Knowing our smallness against the vast canvas of the universe can help us find the humility we need to live outstanding lives. You see, a proud person receives input only from the very few people they consider worthy. A humble person on the other hand is made rich by the multiple inputs they observe, relate, and learn from. The humility of knowing our smallness against time is a deeply rewarding experience that you should create and stimulate in your children. Far from having low self-esteem, they will see and partake in a vision that draws out the very best from them. ',
    value:
      '1. There are several ways of doing this. Engaging with nature is one - a deep forest, an endless desert or an ocean that stretches so far into the horizon, that it looks as if it would spill over. All these things centre us and allow us become receivers of the grace that is found so plentifully around us. By becoming small, we become great. By looking at the bigger canvas, we see the details.\n2. Another route to humility is to seek to solve a huge problem that is costing people their lives, livelihoods, and happiness. Trying to solve intractable problems brings us to the point when we realize we do not have all the answers. It forces us to open ourselves to others and is a vital step to connecting to a powerful network of people and their associated skills.\n3. If you can, take a trip with the family and visit some of the sites of the oldest civilizations such as the Incas, the Aztecs, the Egyptians, Ancient Rome, Turkey or maybe some evidence of an older civilization close to home. The effect this will have on the family is immense. Suddenly they will feel the expanse of time, they will wonder about the men and women that built these cities and artifacts and finally, they will think “how will I be remembered?” “What am I building?”\n4. A solitary star in the sky receives attention, but a star filled sky, elicits awe. There is great value to being connected to large networks and collaborating with us. Such relationships hide your inadequacies and limitations, empowers you, brings resilience and increases the pathways to success. Don’t be lost in the crowd but at the same time don’t be afraid to connect to something larger than yourself.',
  },
  31: {
    key: 'I want to use time meaningfully. My focus is to act as a trustee of family history on behalf of generations to come. I want them to know who we were, how we got here and how they; the family to which this letter is written should live. I hope one day, and in your own way, you will embrace this role. Here are some thoughts:',
    value:
      '1. Your time is important. Use it well.\n2. Honour those who have gone before you because they did, their best with the knowledge and resource available to them.\n3. Know our history and our values. Live them out and you will become immortal in the hearts of the next generation.\nPut in your best. Leave no possibilities unexplored on earth and leave only beautiful memories.',
  },
  32: {
    key: '',
    value: '',
  },
  33: {
    key: '',
    value: '',
  },
  34: {
    key: '',
    value: '',
  },
  35: {
    key: 'Genealogy or family history is important to me both for what it says and what it does not say. We need to remember the generations that came before us; what they accomplished, where they failed, and why it matters. To do this successfully, I respectfully ask you to consider four things.',
    value:
      '1. Ask questions and be inquisitive about what happened in the last generation. Do not be deterred.\n2. Be uniquely yourself but learn from the past.\n3. Do not seek short term popularity but rather do both little and heavy things that will be remembered eternally.\n4. There is a price to pay for family immortality. Start paying on education, energy expended, resources and skill/abilities today. Avoid frivolity, it is like the foam of sea; dramatic but quickly fading away into nothingness.\n/abilities today.',
  },
  36: {
    key: 'There is a saying that a family that plays together stays together. Family interactions - especially those that involve memorable places plus physical exertion release body chemicals that help people bond together better. There is anecdotal evidence that troops that have come under enemy fire bond at a deeper level that troops that have not had such experiences. So, I wholeheartedly recommend that our family goes on adventures to places we are unfamiliar with. Such activities will stimulate our health and intellectual capital and they should be treated with the same seriousness as an investment board meeting. I know that this will be hard to do. But do try to remember that this is where our wealth is both created and reflected - it is our factory and our mirror. The better we do this, the brighter our future will be.',
    value:
      '1. Be led by an expert. Family trips and the physical exertion can look deceptively easy. It doesn’t take much to climb up a mountain in the Andes. But it does take a good guide to explain the history of the terrain and to plan the ascent up the mountain including tools and resources. Try to involve the best guides and experts in the family adventure and set "challenges" and "missions" for each day. The investment is well worth it.\n2. Recognize the diversity of the family group. Sometimes adventure holidays are dumbed down because some members of the family are older or less active. This is thoughtful but not always the best because the product is usually something that pleases nobody. Instead think carefully of locations that have good health care, where medical records can be quickly received and acted on. Then next consider a way in which activities are tiered so that one group can have lunch at the foot of the mountain, another can go halfway, and another group goes all the way to the top!',
  },
  37: {
    key: '',
    value: '',
  },
  38: {
    key: 'My faith has been important to me. It has been a light to guide, a rock to hide in times of danger and an anchor when I felt overwhelmed. I completely recommend God’s word - The Bible to you for comfort, direction and yes, also for reprimand.',
    value:
      '1. For God’s word to speak it must be illuminated. Do all you can do to avoid lifestyle choices that may confuse or hinder you from comprehending, understanding or obeying God’s word.\n2. Sometimes it is good to get away from the trappings of wealth and luxury and go to a place that is clean but bare - where your soul can be free from attachments and memories. Offer yourself to God as a blank canvas. This is the source of valuable and vital re-invention and renewal.\n3. Good food is a blessing but a morning or day with water alone gives room for the soul to soar above the physical senses. I recommend such practices for cleansing the soul of the baggage that come naturally as we journey through life.',
  },
  39: {
    key: '',
    value: '',
  },
  40: {
    key: 'Those in need has often stripped of their self-respect. This is the first thing we must restore. A man or woman who does not have self-respect is unlikely to receive the emotional energy to be independent and useful members of society. In all that we do for the poor - we must not deny or reduce their sense of “Imago Dei" - made in the image of God.',
    value:
      '1. Be respectful of the poor and. Do not in any way demean them through language and conduct. Neither should you allow them to demean themselves because they want or need something from you.\n2. Listen attentively to them without patronizing them.\n3. Where they seem to have fallen into despair- we must restore them with respect allowing them the dignity of making choices and voicing opinions however wrong those opinions may appear to us.\n4. Give basic needs and allow them the dignity of making their own way - time your gifts and assistance and aim towards the restoration of human dignity and not the mere cosmetic salving of your own conscience.\n5. How will you know you have succeeded? It will be when you see them establish habits or rhythm of activity that allows them to meet their needs and obligations.',
  },
  41: {
    key: 'Do not let the faces of the poor repel you. Rather see in their sufferings, the sufferings and nobility of Christ who similarly suffered. We must not let the well coifed hair and done of nails, elegant clothes and perfumery of our wealthy associates be our only emblem of success. The poor battle heavy odds and they are often more hard working that the entitled rich.',
    value:
      '1. Look into the souls of distressed men and women and not at their clothes. The outward may deceive you, but the eyes of the soul never lie. Look for that spark of curiosity, hope and courage and then fan it until it bursts into flames. \n2. You are not called to be all things to the poor. You are not God. What you offer is support and challenge. Yes, challenge because for anyone to thrive they must have the self-worth that comes from doing a job well. Anyone who will not work is not a proper candidate for long term support. ',
  },
  42: {
    key: 'I tried in my own little way, and I want you to also inspire your children and grandchildren to be useful members of society. We must not only take, but we must also give back. When you ask your children what they want to be when they grow do not steer them towards your definition of success. Success can include the diligent nurse, teacher or even postman. Think less of societal expectations and think impact. Let our wealth pay for your children’s dreams.',
    value:
      '1. Let your children cultivate a care for society and consider services such as teachers, nurses, doctors, firemen, police, army, and social workers as "superhero" careers. In doing this. you will save them from two enemies of our long-term wealth - the first is entitlement and the second is stasis. Entitlement is that destructive belief that we deserve the best without putting in great effort. Stasis is that curious situation in which a family lives off its wealth without taking the risks and pain of increasing it. Both conditions must be avoided.\n2. Encourage them to visit and be taught by such people as I have mentioned above. Let them visit physical locations, touch their equipment and soak in the entire ambience of these services in a safe way. They must be made to realize that wealth is built upon the foundations of an orderly civil society - and that without such people, we have zero chance of wealth longevity.',
  },
  43: {
    key: 'In my experience, quantum leaps in progress and innovation come from those people willing to come to the front of the queue and jump into situations that others back off from. These are the people that create new industries, paradigms, opportunities, products, and services. Jumping in where "angels fear to tread" is difficult because human instinct is self-preservation. Bucking this trend produces outstanding opportunities but for you or your children to achieve this will not be an accident. rather, it will be a well taught out process.',
    value:
      '1. Risk takers may look rash but in fact, they simply calculate the odds of success faster than other people. Preparing children to take tasks means you must encourage to ask questions and to think through their answers. Helping children think through problems is an asset. The more this faculty is exercised, the greater the capacity to do so the next time.\n2. Children also need to visualize the gains of success. the gains of success must be larger than the risk for the next generation including yourself to take such risks.\n3. Finally, you must understand that taking no decision is in fact a decision. Circumstances change quickly and sometimes not taking a risk is in fact the greatest risk of all. One of the dangers to our wealth is obsolescence - and it is a challenge that every generation must address. Whatever you do - take decisions. Inactivity for passive family members is as fatal to the cause of our family as a spendthrift. One wastes opportunity, the other wastes money.',
  },
  44: {
    key: '',
    value: '',
  },
  45: {
    key: 'There are few things better than watching a family member being honored. It validates our culture, our abilities and provides great encouragement to others to do better. In addition, it creates additional points of reference for our next generation; they have one more star to travel by, one more leader that inspires. This is especially so when the achievements come through great sacrifice and effort. Right now, you may well be thinking – “this all sounds great but how does this work in the midst of the pressures of life?” ',
    value:
      '1. Enjoy it. As a first step, recognize that the award is first to the actual recipient. Therefore, we must enjoy the occasion. In person if possible. We must let the person know how delighted and excited we are about the achievement. We must not compare it with other family achievements, and we dare not say "nothing less was expected" Individual achievements are part of a larger tapestry of family success but each achievement is special and unique and the circumstances in which it was birthed cannot be repeated. Therefore, we celebrate our family members success so vigorously.\n2. Involve multiple generations. All family members will have their close group of family and non-family friends. But it is important to make our celebrations as multigenerational as possible; grandparents, parents, siblings, and cousins - all should be part of our celebratory feast. This does not only spread our joy, but it also increases the happiness of the celebrant and sends a clear message that we are united in both times of sorrow and times of joy.\n3. Record the event. There must be family archives into which photos, videos and memorabilia are stored. There is also the family genogram to be updated. Try to ensure that there are keepsakes that others who will come long after you are gone can look and learn from.',
  },
  46: {
    key: 'All birthdays are worth celebrating but the birthdays of the older generations are especially so. Older generations carry within them the history and values of our family. We respect their years, their endurance and sacrifice. Their birthdays are one occasion in which we can all come together to recognize and honour these pillars of our family. Such birthdays should not, (even where the older family member is self-effacing), be taken for granted or dismissed. They must become a rallying point for multiple generations of our family members. Because if we honour them, we shall in turn be honored.',
    value:
      '1. Create a centre of focus for the celebration - it could be a cake, a gift, or an award. Something that can provide a memorial and conversation point for all our generations in the future. It doesn’t matter if the celebrants don’t eat cake - still have one - since it is a world acknowledged symbol of birthday celebration. It would also be nice if some of the gifts are made on a generational basis - each gift saying something unique about the celebrant.\n2. Orchestrated fun. Some fun centered birthdays can be chaotic and very insensitive to the physical strength of grandparents. In some of the ones I have seen an ageing grandparent is treated more as a family trophy and made to stand when they would rather sit or to sit when they would rather dance. So, I suggest that there should be a fair amount of planning behind the agenda - what happens and when. But all this should be done in such a way that the spontaneous joy of family members can be fully expressed.\n3. Capture the memory. Sometimes during all the fun, the precious moments are lost forever. It is my opinion that a good photographer and video person is needed for these occasions. Someone who will calmly capture the great moments while the rest of us are losing our heads!',
  },
  47: {
    key: '',
    value: '',
  },
  48: {
    key: '',
    value: '',
  },
  49: {
    key: '',
    value: '',
  },
  50: {
    key: 'We need to encourage interaction between the next generation - especially at cousin level. Such events should be a constant in our family calendar. It is so important members of this generation get to know each other well before they step into the fullness of adult responsibilities. A lot of distrust and communication failure occurs at the cousin level because family ties and affinity feel looser. If you are to do this well, I suggest separate meetings for pre-teens, one for the teenagers and another for young adults. Make these occasions fun, educative and social and watch the next generation bloom into brighter and better flowers than the previous one.',
    value:
      '1. Get the demographics right, not all teenagers feel like teenagers - there is a great difference between a 19-year-old and a 13-year-old. The teen years in particularly can be the "lost years" for the any next generation because is the stage at which peer pressure lures them out of the family\'s embrace and draws them into the "education of the streets." Encounters with peers within the family are no less dangerous but they offer opportunity – big ones as well. If the demographics are rightly designed, these meetings are likely to be more enjoyable to the attendees.\n2. De-emphasize inequalities. Not all next gen will have access to the same level of wealth, and this can lead to a stratification of next Gen which destroys the reason behind the gathering. Maybe parents should drop the children of at a pickup location and then have a bus take the children to the venue. You can also restrict the use of jewelry by drawing up a dress code that emphasizes minimalism, physical activity, and comfort.\n3. Make it fun but informative. You aren\'t simply trying to give them fun - they can find that themselves. You are trying to give them meaningful fun that they can’t get anywhere else. So, weave the magic and make sure that the games and exercises tell us more about each of these valuable next gen leaders so that we together with their parents can design even more customized sessions for them in future.\n4. Introduce "causes" or philanthropic efforts. Young people care deeply about things that they feel helpless to change. Invite them to kickstart their philanthropy or social impact adventure. It doesn\'t have to be perfect, but it needs to positively impact their time, treasure and talents.',
  },
  51: {
    key: 'The grand old family house is a place where divine providence, history, events, and people meet. It is critical that historic family assets should not be sold. No price can equal their real value. In instance where a sale is inevitable, the house must have its history exported as labelled artifacts, photos, and memorabilia. The "new house" should have these artifacts meaningfully embedded so that our story continues uninterrupted.',
    value:
      '1. Maintain the historic family asset with love and if it has declined, lovingly restore it.\n2. There will be many houses but there will be one family house. Treat it specially. Talk to your children about it and walk them through its stories and history.\n3. Let your individual homes breath out your values and history. Let the walls speak to your children. Side by side with your modern fittings and fixtures, let our historic success permeate your living spaces.\n4. Every human being wants to be unique and special. But do not let this be at the cost of our shared family history. Innovate, don’t obliterate.',
  },
  52: {
    key: 'One of the drawbacks of a good education and breeding is that it can make us very self-conscious and uptight - especially in situations that threaten our social status or credentials. It’s so easy to feel like a fraud or an imposter. This is very common in highly educated people because their extensive reading and intelligence alerts them to gaps in their knowledge or abilities. Of course, these gaps don’t make them frauds - no one can know everything. It just makes them feel like frauds - which they aren’t. I am concerned that these feelings which are so common in a world where information is instantaneous will cripple you or my grandchildren. It is important they be addressed wherever they occur.',
    value:
      '1. We need to have perspective. Sometimes when you are in the thick of the action, you can end up comparing yourself to others - others may have learned to hide their insecurities better than you and consequently you may feel a diminished sense of self value. At such times it is good to see things as if you were up in a hot air balloon - because when you look with a wider lens/perspective you will discover that although you could do better - you aren’t that bad. In fact, you are quite good.\n2. Another aid to this feeling is let go of all social pretension and free yourself from your inner critic. Your inner critic can be your worst critic. It sounds authentic, but it is not. Encourage yourself and your children to value themselves to let go of the expectations of society and release themselves from the clutches of the inner critic. Take a break - kick of your shoes and heels and enjoy yourself in an unfamiliar setting. You will soon start feeling much better. Just be audacious and bold.\n3. Finally, it might just be that your skills need to become better fine-tuned or that your life goals need to be better defined. Vagueness of vision can sound elicit a vague mumbles and grumbles from within. The problem with these mumbles and grumbles is that they never ever give a clear direction. But as you sharpen your vision and goals - the less competitors you have and the better you will see the playing field.',
  },
}
