export default {
  emphasizing_criticism_over_praise: {
    excessive_criticism_and_control:
      'I regret that in my efforts to create a perfect child, I spent so much time demeaning and criticizing your efforts. I lost sight of the individuality and unique mission that God sent you to fulfil. Maybe I was trying to live my life better through you. I am sincerely sorry, and I hope that this apology will in some way make up for any pain that I caused you and your siblings. I trust that you will have learned from me. If I am to offer any advice, it is this. There is a danger that you may seek to overcompensate your children for my mistakes. Please know that there is nothing wrong with telling children that they have or are making mistakes, but the style and manner of doing so - especially when such criticism does not come with appreciation, love, and optimism is hugely important.\n',
    unfairly_comparing_children_to_others:
      'Every parent wants their child to be the best. I certainly did. But it seems to me that I spent a lot of time comparing you to other children and for this I sincerely apologize. It was wrong to compare you to other children when in fact you are a lovely unique person with your own special gifts that I did not take sufficient time to discover. I apologize for my parental laziness and short cuts. I am sure you will not make the same mistake. My criticisms and comparisons must have been very painful. Maybe this is the way I was brought up, but it was a mistake. The benchmarks I set were not global and did not come with the level of inspiration and support that would have seen you aim for the stars. I lost a lot of precious time - time that is now difficult if not impossible to recover but I hope that my apology and the sincere love I have towards you will be some compensation, no matter how small and that the lesson my life has provided, will turn into a gift for your children and grandchildren.\n',
    setting_impossible_standards:
      'I set some crazy standards, didn’t I? Some of my expectations for you kept changing from week to week. I failed to break down these standards into bite sized morsels and I doubt I provided sufficient support. But I think the biggest error in my expectations was that it masked my true love and affection for you. You cannot systemize love. Love is like an amazing but messy sandwich - it simply must be experienced, and I did not create enough of such experiences. I trust you will not make the same mistakes that I made - no one knows exactly what the world will look like in the next ten or twenty years - knowledge is growing at such a phenomenal rate and any standards set today will invariably be wrong at some point in the future. The only standards that are possible is that of being a good decent person. To love family, care generously, be an expert in your work, and love and obey God from the depth of your heart - especially towards the poor and unfortunate. My prayers and blessings are now for your success as you define it. I trust you completely. \n',
  },
  failure_to_adequately_provide: {
    financially:
      'While I tried to provide for you as best as I could, I could certainly have done much better. Many opportunities were lost, networks and childhood acquaintances were not well utilized and in many cases the economy moved against me. Despite all this, I confess to a sense of shame that I did not do better for you. It is a thing of joy for a parent to provide for their children and not merely provide but give them the confidence and assurance that comes from a well provided life. I can only trust that you will forgive me and that you will remember that the size of my estate is by no means the extent of my love for you. I hope that you will be able to sift through any advice that I have given to you and that my other failures will not obscure whatever was wise, insightful, and good about the instructions I gave you. I trust that you will have learned from my mistakes but that you will not now work so hard that you forget how to be a decent human being. There are things I cannot make amends for but one thing that will never change is the pride and honour I experienced in being your parent.\n',
    leadership_and_direction:
      'I want to apologize for those critical moments in which I did not provide leadership or a steady hand. I know that in times of crisis, children look to their parents for assurance and leadership. I am ashamed that in many of such occasions I was found wanting. It wasn’t that I didn’t care. Maybe I felt the danger would pass, maybe I hesitated too long or maybe I just did not know what to do. I trust that you will have learned from my mistakes. I make no excuses for them, only a heartfelt apology for any fear, anxiety, and pain that I may have caused you. Please be assured that my inaction was not evidence of a lack of love or unconcern. While making no excuses, please accept that we are formed by our childhood experiences and it is my greatest hope that whatever may have been unpleasant about your childhood memories will serve only to make you stronger, gracious, and more decisive than I was.\n',
    affection:
      "One big and major apology I feel strongly led to make was my inability to provide affection in generous doses. I wonder if this was due to my own background or if it was something I evolved into – the thinking that public shows of affection might somehow spoil you. I was wrong, and I hope that you will forgive me. Age has given me the opportunity to reflect on what true success means. I placed performance over warming your hearts but now I realize that it is the condition of the heart that drives true success. Most of my attention should have focused on communicating the love I undoubtedly feel and have always felt for you. Affection warms the soul of a child and gives birth to curiosity, confidence, and adventure. These three things produce innovation and invention. Sadly, I wasted this opportunity, and spent most of my time trying to elicit measurable performance and results from you - often with the aid of unwarranted criticism. I am happy to have learned that this was wrong, and I am thankful that my humanity returned before it was too late. Otherwise, I would not have been able to even send a letter like this. Please learn from my mistake - as the eagle sits upon her eggs, so we must warm our children's hearts into life. Not just their physical life, but life far beyond merely existing. I have no doubt that you will do a better job with your children than I have done with you, and I commend you to God’s grace and favour.\n",
  },
  failure_to_teach_important_lessons: {
    money_and_investments:
      'If there is something that I neglected to do, and which now brings me great sorrow - it was my inability to teach you about money and investment. We felt we should let you focus on your studies, and I guess, I partially believed it might make you careless towards your studies and entitled towards possessions. This was a great mistake, especially now, that it will form such a large part of your adult life. I wish I could turn back the hands of time. I would love to teach you about the time value of money, investment biases, asset classes and portfolios and currencies. I hope you will find time to learn about these things because money and investment are wrapped up in everything we do - whether it is in buying a house, sending children to school, or deciding which car to buy. I deeply apologize for this failing. Somehow, I passed on my own parent’s neglect to you. I hope that you will not do same, and I wish you all the best of prosperity and happiness.\n',
    communicating_family_history_and_culture:
      'Our family has a rich history going back many years and there are many important lessons that I learned from my father and he from his. Sadly, and to my great regret, I failed to pass on these stories and culture to you. The exigencies of our times meant we were unable to visit our ancestral home and to learn the context in which your predecessors grew. We did not unearth for your benefit, the sacrifices and effort undertaken by these men and women to bring us to where we are today. Even more tragic is that many of the people who could have filled in these details for you, are now old, frail and many have passed on. This is a deep loss. Like many people of my time, we embraced modernity at the cost of family history. It is my hope that you will undertake a quest using the modern tools and resources at your disposal to uncover our rich history and values and to communicate same to your children. I hope you will find the fervor and the time that I sadly did not make good use of. From the depths of my heart, I wish you all the best. \n',
    resilience_and_strength:
      'It was my intention to give my children a good life - one equal to or better than the one I had. In my mind I would spare no expense or effort towards this end. In following this path, I unknowingly did you a disservice and one that I hope you will not repeat with your children. I failed to understand that I not only owed you a duty to protect, care and nourish you but more importantly, because I also owed you a duty to allow you experience events that would strengthen you. In my carefulness I was careless and forgot that our DNA was forged under pressure and with great sacrifice and that every generation must experience in their own way, challenges that open these capabilities. I urge you to expose your children to carefully calibrated challenges that will fire up the resilience, strength, inventiveness, and adaptability that already reside in them. May our strength not be dormant. May our flame not be extinguished. \n',
  },
  hesitation_and_procrastination: {
    spending_time_with_loved_ones:
      'I am sure that many people of my age share the regret of not starting important things on time. As I write this, I feel like a person that fell into a deep sleep and woke up 30 years later to see his children grown and the world changed forever. You know, hesitation is caused by feelings of inadequacy or maybe even the need to be completely perfect before starting anything. We all suffer from “imposters syndrome” that tells us that we aren’t as good as people think we are. On my part, I allowed these thoughts to grow and fester, and they robbed me of the many moments of opportunity in relationships and business. I think at some stage in my life, I may have undervalued myself. I held myself to impossible personal standards that paralyzed instead of enabling me. I pray with all my heart that you will not fear rejection or criticism to the extent that you hide your light and talents. Be bold in life. What’s the worst that can happen? People criticize you or dislike you. But don’t people criticize everything that they do not understand? And how many people have a perfect vision of the future. I want you to realize, you have more than enough. You are good enough. Completely sufficient for what is at hand. What’s more, you have a duty to take chances, to stretch yourself and to believe to the stars. I wish we had spent more time together. I hope you find these words to be of value. In that way, perhaps, I will have made up for any deficiencies of overthinking and \n',
    pursuing_financial_opportunities:
      'We could have been a far more wealthy and influential family. Not just for the sake of ranking ourselves among a dubious elite, but to provide the wood and the fuel for the dreams of you our children and future generations. How did this happen? There were many opportunities that I deemed as two risky (some in fact were) and I shied away from them. But they in fact were the places where I would have made outsized returns. In any society that is inefficient and unstable, these types of opportunities exist. Sifting out the dangerous from the ones with acceptable risk is something I failed to do. I was taught that if I did my work well, good things would come. And they did, but I was slow in recognizing them because they were outside my comfort zone or because I would meet people unlike myself or enter unfamiliar terrain. I always intended to respond, but usually, by the time I had steeled myself to answer, the opportunity had passed me by. This is a regret because opportunities do not come around regularly; sometimes there are lots of them and at other times, none. Opportunities must be analyzed, quickly seized, and captured. I was not prepared, and my hopes and prayers are that you will be better prepared than I was. \n',
    chasing_personal_dreams:
      'As we approach the end of life, it is always the “small” things that bring regret. It is the places we did not visit, the people we did give more time to, and the ambitions we did not fulfil. It is scarcely ever about working harder or making more money. At the end of life, money is usually the last thing on one’s mind. I want you to escape the regret that I felt. The holidays that were not taken, the hobbies that were never started, the challenges that were never conquered and the friendships and relationships that were never watered and nurtured. Do you want to stand on Table Mountain in South Africa or swim with dolphins in the Bosporus? Do it. Do you want to explore ancient Rome, visit the catacombs, or visit the site of the seven churches in the Revelation? Do you want to learn fine pottery or learn the piano at the hand of a world renown master? Do it. Leaving these "senseless" and "unnecessary" dreams undone brings the greatest sorrow in old age. So don\'t hesitate at the edges of life\'s waters, jump in. Underneath you are arms that will carry you. Don\'t say “I will do this tomorrow”. Remember a life lived in fear, is a life half lived. Take the first step and then the second - enjoy your gift of life on earth. It will not come round again.\n',
  },
  not_taking_care_of_own_health: {
    not_adopting_a_healthy_lifestyle:
      'If there is a regret that is difficult to reverse, it will be the way I managed my body and health. I neglected a healthy life - I overextended myself with work, ate what was unwise or in quantities that were extravagant. I felt invincible and I postponed changing my lifestyle. The result was that at the very time I needed energy to enjoy the fruits of my labour, I became constrained. My dear children, running a hard race is no good if you cannot enjoy the celebration after you finally cross the tape. I did not understand that not only was I to steward and care for you, but I was also called to care and steward my own body. It is my greatest hope and desire that you will not repeat my mistake. That you will overcome your fear of hearing bad news and make regular hospital checks part of your lifestyle. Our bodies are like cars, and they need servicing from time to time. And this servicing can only be done by skillful and well-trained people - not by a YouTube video! I know that it is hard to give up control of your body to a "stranger" even if it is for a short time - but do we not gladly do this when being fitted for new clothes or when travelling through an airport? The inconveniences of medical checks are fleeting but their benefits are lasting. I commend them to you with all my love and concern for your well-being.\n',
    overextending_the_body:
      'I prided myself on how hard I could work without a break, and I was applauded for it. Rewards of the monetary type and the praise of work colleagues followed. Today, I wish that I had not listened to their siren songs. My health which was robust at birth, became a shadow of itself. Although, I was surrounded by the evidence of a successful life, I could not enjoy them. My life became a steady diet of pills, restrictions, and hospital visits. At many of the events I was invited to, I was an onlooker; unable to get physically and wholeheartedly involved. This is a regret that only providence can change, and I certainly have abused the kind providence of a healthy youth and early adult life. So please do not overextend your body with work, or food or anything else. Live a life of moderation and simplicity. We brought nothing into the world, and we shall take nothing out of it except our good works will which act as our herald in the world to come. Live life always within your capacity – life is a marathon not a sprint. My prayer for you is that your latter days will be your best days and that you will have no regrets for either under achievement or excessive work.\n',
    addictions:
      'It may surprise you to know that my major regret in life was my addiction. I am not talking about substance abuse but of addictions that kept me away from my family - the addiction to work, the workplace and the various activities associated with being a successful professional or businessperson. I painfully regret the time I spent away from you and the family. It took me a while to realize that behind the various "good" reasons I gave for my frequent absences, i was in fact gripped by a serious addiction to the praise of others. What makes it most painful is that not only did I prefer the praise of work colleagues and clients, but I even preferred to praise from my own family! Today, where are those that praised me for my hard work? What comforts did they give to me and my family? How does a gold wristwatch, a plaque or a monetary gift compensate for the lifetime that I spent away from the people I love? Today, I can only warn that work and the praise of men is extremely addictive. The chemicals it releases into the body are little different from those caused by hard drugs and substance abuse. If you ever find yourself in such a position, please seek help, have a good long talk with your family, cut back your hours\' and if need be, take a break from work or resign. Praise is fleeting but relationships of blood and affinity are permanent.\n',
  },
  poor_marital_relationships: {
    the_conflict_filled_home:
      "It was always my dream that my spouse and I would create a peaceful and happy home. But somehow, we failed, and I take full responsibility. The many conflicts between the two of us, spilt into your lives, forcing you to become premature arbitrators and parents of your own parents. We forced you to take sides and in so doing, we forced the past upon you, rather than allow you build an exciting new future for yourself. I am wholeheartedly sorry, and I will not try to exonerate myself. It seems then that winning an argument became more important than your happiness. Time and time again we chose our opinion over your joy. We have regretted this every day of our lives. If there is room in your heart to forgive us, we plead with you to find it. Let us bring an end to the many civil wars that have drained us and potentially divided you. Neither of us was right, though I take personal responsibility. I hold the faintest of hopes that your experiences will somehow help you not only to avoid our sins but also to help others out there to successfully pass through the trauma we forced on you.\n",
    lack_of_rhythm_and_process:
      'One word I can use to describe our time together is chaos. No two days were alike. We were always in one crisis or the other. And during all this going and coming, we lost sight of you. We were always involved in one project or another - undertaking one cause or solving some problem somewhere. We or should I say I, failed to build the stable seasons and rhythms that would have nurtured our family. In the pursuit of helping everyone and making things up as we went along, we failed to build you the daily patterns that would create safety, tradition, and fruitfulness. We were supposed to have had seasons of rest, of work, of contemplation, prayer, and family. We should have built traditions that we would pass down to the next generation. Instead, we lived without any real purpose other than surviving and getting things done. I had always that one day we would settle down and do life better, but I now realize that there is no time like the present. Building a family rhythm should have started immediately. If you can do what I failed to do, then you will succeed in building seasons of repetitive success and your children will call you great. Please do this. Become this.\n',
    choosing_outsiders_over_family:
      'In my time, our family had a reputation as people who could be counted upon to help others out. We helped to build, establish, and even save many families from distress. We cared, battled, and fought wars - most of them other peoples. But for my dear children, this came at a cost and the price was heavy because we failed to pay sufficient attention to our family, relationships, finances, and resources. We did not develop a guiding vision or mission. This was a great shame, and we humble ask your forgiveness. We were driven by a need to save others, but could it be that part of us simply wanted the thanks and adulation of outsiders? I cannot answer that question, but I know that a huge mistake was made. All our children are dearly loved. We doted on you even before you were born. You were the centre of our attention when you came into the world. But somehow, we allowed ourselves to drift away from showing you love and concern and instead left you in the hands of paid professionals for whom you may have been just a paycheck. I hope you can please find it in your heart to forgive me. It was not intentional nor was it desired. We simply slipped slowly out of orbit and began to place people and things outside our home at the top of our priorities. You see, we thought we could always stop and then focus on you. But these things developed a life of their own and before we knew it, we were trapped in a quicksand of our own making. I hope and pray desperately that you will not repeat our mistake. There is nothing more important than family. It is the centre of all human identity. I hope that you will intentionally and deliberately craft plans to keep you from the mistake that we made. To guard you time and energy carefully. You have our prayers, our good wishes and of course your own talents and skills. Godspeed.\n',
  },
  wasted_time_and_opportunities: {
    failure_to_use_time_together_properly:
      'One of my regrets was that I did not fully make the most of our time together. I failed to see that you - my children were my greatest undertaking and for this I apologize deeply. We may never get the chance to relive those opportunities; to play, laugh, and to get to know one another at a much deeper level, but I want to know, you were my best and highest priority. I simply acted it out in ways that were unclear. For this, I seek your forgiveness and hope that you will learn from my mistakes and spend time getting to know your own children better; doing all those things with them, that God has set in your heart to do.',
    failure_to_create_more_beautiful_moments_together:
      "If I could have my time with you back, I would live my life entirely different. I would be your playmate, your first date, your mentor, and your friend. It isn't that I didn’t know that these things were important or that I was falling short. It’s simply that I thought we had more time and I procrastinated waiting for better opportunities. In the end, the brilliance and joy of your childhood passed me by. As I write now, my best memories are of the time we spent together. I only wish there could have been more of them. Please treat each moment with your children as a gift to be savored and enjoyed. Don't always be an adult - you can be that in the office. Don't re-live my life, live yours. Show your love by making time and opportunity to tell children in myriads of ways just how precious they are to you. Remember you only have today.\n",
    placing_work_over_family_time:
      'I sincerely thought that everything I was doing was for you and that the times I spent away from you were worthy sacrifices for a better future for us all. I apologize. Now I see that there was a much higher value in our playing, eating out, park visits, board games and conversations than in the money I gave you. The loss has been mine too. Money is important but at its best, it can only purchase beautiful memories. I trust, pray, and hope that you will act differently towards your own children and that the pain I must have given you will translate to additional joy and pleasure for your children and the generations after them.\n',
  },
}
